// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../variables/colors';
@import '../../mixins/mixins.scss';

// -----------------------------------------------------------------------------------------------------
// @ Mat Select
// -----------------------------------------------------------------------------------------------------
.mat-form-field-infix {
  height: 40px;
  color: $gray-500;
  border-top: 0;
  padding: 0 0;

  .mat-select {
    @include text-font-inter;
    font-size: 14px;
    line-height: 35px;
    padding: 0 0 0 10px;
    position: relative;
    top: -2px;
  }
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  padding: 8px 10px 0 0;
}

//hover
.mat-form-field-appearance-outline:hover .mat-select-arrow-wrapper .mat-select-arrow {
  background-color: #1B4693 !important;
}

//Focus
.mat-form-field-appearance-outline.mat-focused .mat-select-arrow-wrapper .mat-select-arrow {
  background-color: #1B4693 !important;
}

//Icone disabled some
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-select-arrow-wrapper .mat-select-arrow {
  display: none !important;
}

// Altera o tipo da seta
.mat-select-arrow {
  width: 10px !important;
  height: 5px !important;
  border: 0 !important;
  margin: 0 !important;
  background-color: $gray-300 !important;
  clip-path: polygon(50% 68%, 10% 0%, 0% 12%, 50% 100%, 100% 12%, 90% 0%) !important;
}

.mat-select-panel .mat-option {
  @include text-inter-h4;
}


.mat-option.mat-active{
  color: black !important;
  background-color: #EBEBEB !important;
  border: 2px solid #102956 !important;
  .mat-pseudo-checkbox {
    color: $blue-600 !important;
  }
}

.mat-option.mat-active.mat-selected{
  color: white;
  background-color: #DCE7F9 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white;
  background-color: #1B4693;
}

.mat-option:hover:not(.mat-option-disabled){
  font-weight: bold;
  background-color: #DCE7F9 !important;
  color: #000000;
}

.mat-option.mat-selected:hover:not(.mat-option-disabled){
  font-weight: bold;
  color: #000000;
}


// -----------------------------------------------------------------------------------------------------
// @ Ngx Mat Select Search
// -----------------------------------------------------------------------------------------------------

.filter-panel {
  max-width: 120px !important;
}

.filter-container .mat-select-arrow {
  display: none;
}

ngx-mat-select-search {
  .mat-select-search-inner.mat-select-search-inner-multiple {
    height: 43px !important;
  }

  .mat-select-search-no-entries-found {
    padding: 16px 12px !important;
    color: $gray-500;
  }

  .mat-select-search-clear {
    top: 0 !important;
  }
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  font-size: 14px !important;
  line-height: 2em !important;
  height: 42px !important;
}