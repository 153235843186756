// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../mixins/mixins';
@import '../../variables/colors';

// -----------------------------------------------------------------------------------------------------
// @ Mat Slide Toggle
// -----------------------------------------------------------------------------------------------------

.mat-slide-toggle-content{
  @include text-inter-medium;
}

.mat-slide-toggle-thumb {
  width: 15px !important;
  height: 15px !important;
  transform: translate(10%, 15%);
  box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.10);
}

.mat-slide-toggle-bar {
  background-color: $gray-200;
  border-radius: 15px !important;
  height: 20px !important;
  width: 37px !important;
}

.mat-slide-toggle-thumb-container {
  top: 0 !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  transform: translate(10%, 8%);
  background-color: $primary-color-medium;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: $white;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  border: 1px solid $primary-color-medium;
  background-color: $white;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  transform: translate(10%, 10%);
  background-color: $primary-color-medium;
}
