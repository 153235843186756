.mat-tab-label {
  color: #333333;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-width: 60px !important;
  height: 36px !important;
  width: 184px !important;
}

.mat-tab-label.mat-tab-label-active {
  color: #1B4693 !important;
  opacity: 1 !important;
}