@import './variables/colors.scss';
@import './mixins/mixins.scss';
@import './themes/app.theme.scss';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

// -----------------------------------------------------------------------------------------------------
// @ Fonts
// -----------------------------------------------------------------------------------------------------

@font-face {
  font-family: $font-family-material;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/font/material-icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

@font-face {
  font-family: $font-family-material-outlined;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/font/material-icons/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
}

.material-icons {
  font-family: $font-family-material;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: $font-family-material-outlined;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Inter Thin";
  font-style: thin;
  font-weight: 100;
  src: url("../assets/font/Inter/static/Inter-Thin-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Extra Light";
  font-style: extra-light;
  font-weight: 200;
  src: url("../assets/font/Inter/static/Inter-ExtraLight-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Light";
  font-style: light;
  font-weight: 300;
  src: url("../assets/font/Inter/static/Inter-Light-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Regular";
  font-style: regular;
  font-weight: 400;
  src: url("../assets/font/Inter/static/Inter-Regular-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Medium";
  font-style: medium;
  font-weight: 500;
  src: url("../assets/font/Inter/static/Inter-Medium-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Semi Bold";
  font-style: semi-bold;
  font-weight: 600;
  src: url("../assets/font/Inter/static/Inter-SemiBold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Bold";
  font-style: bold;
  font-weight: 700;
  src: url("../assets/font/Inter/static/Inter-Bold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Extra Bold";
  font-style: extra-bold;
  font-weight: 800;
  src: url("../assets/font/Inter/static/Inter-ExtraBold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter Black";
  font-style: black;
  font-weight: 900;
  src: url("../assets/font/Inter/static/Inter-Black-slnt=0.ttf");
}


@font-face {
  font-family: "Manrope Regular";
  font-style: black;
  font-weight: 400;
  src: url("../assets/font/Manrope/static/Manrope-Regular.ttf");
}


$font-family-base: "Inter";

// -----------------------------------------------------------------------------------------------------
// @ Default
// -----------------------------------------------------------------------------------------------------

::-webkit-scrollbar {
  width: 2px;
  height: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #EBF5FF;
}
::-webkit-scrollbar-thumb {
  background: $blue-600;
}
::-webkit-scrollbar-thumb:hover {
  background: $blue-400;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Inter Regular", "Helvetica Neue", Arial, sans-serif !important;
}

// MyToastr
.toast-top-right {
  top: 12px;
  right: 12px;
  position: absolute;
}

// modal
.modal-container {
  position: relative;
  margin-top: -1rem;
  padding: 0 1rem;
  border-radius: 0.25rem;
  overflow: hidden;

  > header > div, > footer > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  > header {
    &, > div {margin-bottom: 1rem;}
    > div {
      justify-content: space-between;
      > h4, h5 {
        display: flex;
        gap: 0.5rem;
        color: $gray-500;
        margin: 0;
        padding: 0;
        .icon-modal {
          font-size: 1.25rem !important;
          line-height: 1.2 !important;
          width: 1.25rem !important;
          height: 1.25rem !important;
          &.blue-icon {
            path {
              fill: $blue-300 !important;
            }
          }
          &.green-icon {
            path {
              fill: $green-300 !important;
            }
          }
          path {fill: $yellow-400 !important;}
        }
      }
      > h4 {
        @include text-inter-h4-semibold;
        .icon-modal {
          margin-top: 2px;
        }
      }
      > h5 {
        @include text-inter-large;
        .icon-modal {
          margin-top: -1px;
        }
      }

      > button {
        background-color: transparent;
        border: none;
        .material-icons {
          transition: color .3s ease;
          width: 16px;
          height: 16px;
          color: $gray-400;
        }
        &:hover {
          color: $gray-500;
        }
      }
    }
  }

  > footer {
    > div {
      justify-content: flex-end;
    }
    &.divider, > div {margin-top: 1rem;}
  }
}

.content-informations {
  &, > div {
    display: flex;
    gap: $gap-default-container;
    flex-direction: column;
  }
  > div {
    > h6 {
      @include text-inter-medium-semibold;
    }
    > ul {
      display: flex;
      gap: $gap-default-container;
      list-style: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }
  }
  &.other-informations {
    padding: 0;
    height: 0;
    pointer-events: none;
    opacity: 0;
    transition: padding .3s linear, height .3s linear, opacity .1s linear;
    &.active {
      height: auto;
      pointer-events: all;
      opacity: 1;
    }
  }
}

.group-description {
  > p {
    margin: 0;
    &:first-child {
      @include text-inter-small;
      color: $gray-500;
      margin-bottom: 0.625rem;
    }
    &:last-child {
      @include text-inter-medium;
      display: inline-block;
      color: $gray-400;
      background-color: $gray-100;
      padding: $gap-xsmall-container $gap-small-container;
      border-radius: $border-radius-default;
      word-break: break-word;
      white-space: pre-line;
    }
  }
}

@media only screen and (max-width: 812px) {
  .content-informations, .content-informations > div, .content-informations > div > ul {
    gap: 0.75rem;
  }
}

@media screen and (max-width: 700px) {
  .modal-container {
    > header {
      &, > div {margin-bottom: 0.875rem;}
      > div {
        > h5 {
          font-size: 1rem;
          line-height: 1.5rem;
          .icon-modal {
            font-size: 1.125rem !important;
            width: 1.125rem !important;
            height: 1.125rem !important;
          }
        }
      }
    }
    > footer {
      &.divider, > div {margin-top: 0.875rem;}
    }
  }
}

@media only screen and (max-width: 599px) {
  .modal-container {
    > header {
      > div {
        &:not(.contains-close-button) {
          justify-content: center;
        }
      }
    }
    > footer {
      > div {
        align-items: stretch;
        gap: 1rem;
        flex-direction: column-reverse;

        button-induxtry {
          width: 100% !important;
          .buttons {
            display: block !important;
            &, .button-generic{
              width: 100% !important;
            }
            .button-generic {
              justify-content: center !important;
              height: 2.5rem !important;
            }
          }
        }
      }
    }
  }

  .none-mobile {display: none !important;}

  .cdk-global-overlay-wrapper {
    align-items: flex-end !important;
    justify-content: stretch !important;
    .cdk-overlay-pane {
      max-width: 100vw !important;
      width: 100vw !important;
      animation: openDialogMobile .3s forwards;
    }
  }

  .mat-dialog-container {
    transform: none !important;
  }
  .page-system-spic {
    .mat-drawer-content {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }
  }
}

@keyframes openDialogMobile {
  0% {
    transform: translateY(6.25rem);
  }
  100% {
    transform: translateY(0px);
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.container-menu-mobile > nav {
  z-index: 998 !important;
}

.not-show-offline {display: none !important;}

::ng-deep .user-name .perfil-profile {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  border-radius: 1.25rem;
  background-color: #EBF5FF;
}
::-webkit-scrollbar-thumb {
  transition: all .2s;
  background: $primary-color-dark;
  border-radius: 0.25rem;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary-color-medium;
}

.btn-download-graphics {
  position: absolute;
  top: 1rem;
  right: 1rem;
}



/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
